@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

@layer base {
  @font-face {
    font-family: 'Hofmann-TRIAL';
    src: url('assets/fonts/Hofmann-TRIAL.otf');
  }
  @font-face {
    font-family: 'Monopol-TRIAL';
    src: url('assets/fonts/Monopol-TRIAL.otf');
  }
}

.resize-handle {
    position: absolute;
    width: 10px;
    height: 10px;
}

.top-left {
    top: -5px;
    left: -5px;
}

.top-right {
    top: -5px;
    right: -5px;
}

.bottom-left {
    bottom: -5px;
    left: -5px;
}

.bottom-right {
    bottom: -5px;
    right: -5px;
}


